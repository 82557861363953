import React from "react";
import Layout from "../components/layout";
import FAQs from "../components/homepage/faqs";
import ProductFAQs from "../components/product/faqs";
import HairFAQs from "../components/hair/faq";
// import FAQPageHeader from "../components/product/faqs"

const FAQPage = () => {
  return (
    <Layout>
      <div className="main-content faqPage">
        {/* Please convert the below div into the FAQPageHeader component and wire into contentful */}
        <div className="faqHeader" id="general">
          <h1>Brigo FAQ</h1>
          <div class="anchorLinks">
            <li>
              <a href="#general">General</a>
            </li>
            <li>
              <a href="#erectile-dysfunction">Erectile Dysfunction</a>
            </li>
            <li>
              <a href="#hair-health">Hair Loss</a>
            </li>
          </div>
        </div>
        <h2 class="faqPageItem"> General Questions</h2>
        <FAQs />
        <h2 class="faqPageItem itemTwo" id="erectile-dysfunction">
          {" "}
          Questions on Erectile Dysfunction
        </h2>
        <ProductFAQs />
        <h2 class="faqPageItem itemTwo" id="hair-health">
          {" "}
          Questions on Hair Loss
        </h2>
        <HairFAQs />
      </div>
    </Layout>
  );
};

export default FAQPage;
