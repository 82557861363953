import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

const FAQs = () => {
  const [toggleFaq, setToggleFaq] = useState(false);
  const [showFaq, setShowFaq] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setShowFaq(true);
      } else {
        setShowFaq(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const data = useStaticQuery(graphql`
    {
      contentfulProductProductFaq10(page: { eq: "hair-health" }) {
        title
        subtitle
        questionAnswer {
          id
          question
          answer {
            answer
          }
        }
      }
    }
  `);
  const {
    title,
    subtitle,
    questionAnswer,
  } = data.contentfulProductProductFaq10;
  let showLess =
    !toggleFaq && questionAnswer.length > 6 && questionAnswer.slice(0, 6);
  let showMore = toggleFaq && questionAnswer;

  const renderFaqs = (id, el) => (
    <div className="option" key={id}>
      <input type="checkbox" id={`toggle${id}`} className="toggle" name="faq" />
      <label className="title" htmlFor={`toggle${id}`}>
        {el.question}
      </label>
      <div className="content">
        <p>{el.answer.answer}</p>
      </div>
    </div>
  );
  return (
    <div className="help_center_main_section">
      <div className="container">
        <div className="help_center_inner_section">
          <div className="common-section-label">
            <p>{title}</p>
          </div>
          <div className="common-section-light-title">
            <h2>{subtitle}</h2>
          </div>
          <div className="help_main_accordion">
            {!showFaq &&
              questionAnswer?.map((el) => {
                return (
                  <div className="accordion accordion1" key={el.id}>
                    <div className="option">
                      <input
                        type="checkbox"
                        id={`toggle${el.id}`}
                        className="toggle"
                        name="faq"
                      />
                      <label className="title" htmlFor={`toggle${el.id}`}>
                        {el.question}
                      </label>
                      <div className="content">
                        <p>{el.answer.answer}</p>
                      </div>
                    </div>
                  </div>
                );
              })}

            {showFaq && (
              <div className="accordion accordion1">
                {toggleFaq
                  ? showMore?.map((el) => renderFaqs(el.id, el))
                  : showLess?.map((el) => renderFaqs(el.id, el))}
                <div className="common-section-btn">
                  <button
                    onClick={() => setToggleFaq(!toggleFaq)}
                    className="faq-toggle"
                    style={{ marginTop: "20px" }}
                  >
                    {toggleFaq ? "See Less" : "See More"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
